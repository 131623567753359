<template>
    <Dialog v-model:visible="entryDialog" :style="{ width: '450px' }" :header="'Anzeigewerte bearbeiten [' + ioDialogData.i + ']'" :modal="true" class="p-fluid" @hide="hideDialog">
        <div class="field">
            <p>Wird das Feld <b>Label</b> nicht ausgefüllt, wird der zugehörige IO-Name automatisch angezeigt</p>
        </div>
        <div class="field">
            <label for="name">Name</label>
            <InputText id="name" v-model.trim="ioDialogData.name" required="false" autofocus />
        </div>
        <div class="field">
            <label for="label">Label</label>
            <InputText id="label" v-model.trim="ioDialogData.label" required="false" autofocus />
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label for="type">Feldbreite</label>
                <InputNumber id="width" v-model="ioDialogData.width" mode="decimal" showButtons :min="1" :max="12" />
            </div>

            <div class="field col">
                <label for="background">Hintergrund</label>
                <Dropdown id="style.background" v-model="ioDialogData.style.background" :options="colors" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'bg-' + slotProps.value">
                            <span>Hintergrund</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'bg-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>

            <div class="field col">
                <label for="foreground">Vordergrund</label>
                <Dropdown id="style.foreground" v-model="ioDialogData.style.foreground" :options="colors" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'fg-' + slotProps.value">
                            <span>Vordergrund</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'fg-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div class="field">
            <label for="pattern">Muster</label>
            <Dropdown id="style.pattern" v-model="ioDialogData.style.pattern" :options="patterns" optionLabel="label" optionValue="value">
                <template #value="slotProps">
                    <div :class="'bg-white fg-black ptrn-' + slotProps.value">
                        <span>Muster</span>
                    </div>
                </template>
                <template #option="slotProps">
                    <div :class="'bg-white fg-black ptrn-' + slotProps.option.value">
                        <span>{{ slotProps.option.label }}</span>
                    </div>
                </template>
            </Dropdown>
        </div>

        <div class="field">
            <div class="field col-6">
                <label for="parameterObject" class="mb-3">Zugeordnetes Parameterobjekt</label>
                <Dropdown
                    id="parameterObject"
                    v-model="ioDialogData.bindings.moduleScheme"
                    :options="getModuleScheme"
                    optionLabel="label"
                    optionValue="scheme"
                    placeholder="ventsController,..."
                ></Dropdown>
            </div>
            <!-- <label for="data.id">Auswahl des Wertes</label> -->
            <!-- <Tree :value="nodes" selectionMode="checkbox" v-model:selectionKeys="selectedKeys" :expandedKeys="expandedKeys" :filter="true" filterMode="strict" filterPlaceholder="Wert suchen..." @node-select="onNodeSelect"></Tree> -->
        </div>

        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters } from 'vuex';
import { isUUIDv4 } from "@/helpers";

export default defineComponent({
    name: 'dialogIoMulti',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    created() {
        this.$store.dispatch('users/loadUserGroups');
        this.$store.dispatch('types/loadUnitTypes');
        this.$store.dispatch('types/loadCategoryTypes');
        this.$store.dispatch('types/loadDepartmentTypes');
        this.$store.dispatch('types/loadIconTypes');
        this.$store.dispatch('opcua/loadModules');
        this.$store.dispatch('opcua/loadModuleScheme');
        this.$store.dispatch('opcua/loadModuleTypes');
        this.$store.dispatch('opcua/loadModuleBindings');
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        // for (let node of this.nodes) {
        //     for (let i = 0; i < this.ioObj.length; i++) {
        //         if (node.key === this.ioObj[i].metadata.category) {
        //             this.expandNode(node, this.ioObj[i]);
        //             this.selectedKeys[node.key] = {};
        //             this.selectedKeys[node.key].partialChecked = true;
        //         }
        //     }
        // }
        console.log(this.ioDialogData);
    },
    computed: {
        ...mapGetters({
            getUserGroup: 'users/getUserGroups',
            getCategory: 'types/getCategory',
            getDepartments: 'types/getDepartments',
            getUnits: 'types/getUnits',
            getIcons: 'types/getIcons',
            getModules: 'opcua/getModules',
            getModuleScheme: 'opcua/getModuleScheme',
            getModuleTypes: 'opcua/getModuleTypes',
            getModuleBindings: 'opcua/getModuleBindings',
            getObjectFactor: 'getObjectFactor',
            getObjectDecimal: 'getObjectDecimal',
        }),
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
    },
    data() {
        return {
            loading: true,
            entryDialog: false,
            ioDialogData: {},
            // ioObj: {},
            submitted: false,
            selectedKeys: {},
            expandedKeys: {},
            componentData: [],
        };
    },
    methods: {
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: [],
            };
            this.selectedKey = {};
            this.expandedKeys = {};
            this.componentData = [];
        },
        expandNode(node, ioObj) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === ioObj._id) {
                        // this.selectedKeys[ioObj._id].checked = true;
                        this.selectedKeys[ioObj._id] = {};
                        this.selectedKeys[ioObj._id].checked = true;
                        console.log(ioObj);
                    }
                    // for (let i=0; i<this.ioObj.length; i++) {
                    //   if (child.key === this.ioObj[i]._id) {
                    //     this.selectedKeys[this.ioObj[i]._id] = true;
                    //     console.log(this.ioObj[i]);
                    //   }
                    // }
                }
            }
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i >= 0 || isUUIDv4(this.ioDialogData.i)) {
                console.log('YUHU');
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};

                this.ioDialogData.data = [];
                for (let [key] of Object.entries(this.selectedKeys)) {
                    if (key.match(/^\d+/)) {
                        let dataId = {};
                        dataId.id = key;
                        this.ioDialogData.data.push(dataId);
                        // console.log(key, value);
                    }
                }
                console.log(this.ioDialogData);

                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        onNodeSelect(node) {
            // const newObj = store.getters.getValue(node.key);
            // this.selectedKeys = node.key;
            // this.ioDialogData.data.id = node.key;
            // console.log(this.selectedKeys);

            // for (let [key, value] of Object.entries(this.selectedKeys)) {
            //   if (key.match(/^\d+/)) {
            //     this.componentData.push(key);
            //     console.log(key, value);
            //   }
            // }
            // console.log(this.componentData);

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail:
                    // newObj.metadata.name + "\n" + this.selectedKeys + "\n" + newObj.nodeId,
                    node,
                life: 3000,
            });
        },
    },
});
</script>
