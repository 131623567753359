<template>
	<div v-if="isMaster">
		<p class="text-md fg-white bg-black">{{ widgetName }} / {{ widgetLabel }} <span v-if="isMaster" class="text-xs">{{ widgetId }}</span></p>
	</div>
	<div class="custom-skeleton p-4">
		<div class="flex mb-3">
			<div>
				<Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
			</div>
			<div>
				<Skeleton width="10rem" class="mb-2"></Skeleton>
				<Skeleton width="5rem" class="mb-2"></Skeleton>
				<Skeleton height=".5rem"></Skeleton>
			</div>
		</div>
		<Skeleton width="100%" height="100px"></Skeleton>
		<div class="flex justify-content-center mt-3">
			<Skeleton width="4rem" height="2rem"></Skeleton>
			<Skeleton width="4rem" height="2rem"></Skeleton>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'

export default defineComponent({
  name: "loaderSkeleton",
  setup() {},
  props: {
		widgetInfo: {
				type: Object,
				required: false,
		},
  },
  watch: {
  },
  computed: {
		...mapGetters({
				isMaster: 'auth/isMaster',
		}),
		widgetName: function () {
			if (this.widgetInfo && this.widgetInfo.component) return this.widgetInfo.component;
			return '';
		},
		widgetId: function () {
			if (this.widgetInfo && this.widgetInfo.i) return this.widgetInfo.i;
			return '';
		},
		widgetLabel: function () {
			if (this.widgetInfo && this.widgetInfo.label) return this.widgetInfo.label;
			return '';
		},
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
		console.log('widgetinfo', this.widgetInfo);
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
</style>