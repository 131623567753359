<template>
    <Dialog 
        v-model:visible="entryDialog"
        :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
        :style="{ width: '40vw' }"
        :header="'Parameterliste bearbeiten [' + ioDialogData.i + ']'"
        :modal="true"
        class="p-fluid"
        @hide="hideDialog"
    >
        <div v-if="editMode">
            <div class="field">
                <p>Wird das Feld <b>Label</b> nicht ausgefüllt, wird der zugehörige IO-Name automatisch angezeigt</p>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="name">Name</label>
                    <InputText id="name" v-model.trim="ioDialogData.name" required="false" autofocus />
                </div>
                <div class="field col">
                    <label for="label">Label</label>
                    <InputText id="label" v-model.trim="ioDialogData.label" required="false" autofocus />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="component">Component</label>
                    <InputText id="component" v-model="ioDialogData.component" required="false" readonly disabled />
                </div>
                <div class="field col">
                    <label for="id">ID</label>
                    <InputText id="id" v-model="ioDialogData.i" required="false" readonly disabled />
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col">
                    <label for="type">Feldbreite</label>
                    <InputNumber id="width" v-model="ioDialogData.width" mode="decimal" showButtons :min="1" :max="12" />
                </div>

                <div class="field col">
                    <label for="background">Hintergrund</label>
                    <Dropdown id="style.background" v-model="ioDialogData.style.background" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'bg-' + slotProps.value">
                                <span>Hintergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'bg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>

                <div class="field col">
                    <label for="foreground">Vordergrund</label>
                    <Dropdown id="style.foreground" v-model="ioDialogData.style.foreground" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'fg-' + slotProps.value">
                                <span>Vordergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'fg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>

            <div class="field">
                <label for="pattern">Muster</label>
                <Dropdown id="style.pattern" v-model="ioDialogData.style.pattern" :options="patterns" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'bg-white fg-black ptrn-' + slotProps.value">
                            <span>Muster</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'bg-white fg-black ptrn-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div class="field">
            <div class="field col-12">
                <label for="moduleScheme" class="mb-3">Zugeordnetes Parameterobjekt</label>
                <Dropdown
                    id="moduleScheme"
                    v-model="selectedScheme"
                    dataKey="displayName"
                    :options="getModuleScheme"
                    optionLabel="label"
                    optionValue="scheme"
                    placeholder="ventsController,..."
                ></Dropdown>
            </div>
        </div>
        <div v-if="selectedScheme !== null" class="field">
            <div class="field col-12">
                <label for="parameterObject" class="mb-3">Auswahl des Parameters</label>
                <Listbox 
                    v-model="selectedParameter" 
                    :options="currentModuleScheme" 
                    dataKey="key"
                    filter 
                    optionLabel="label"
                    listStyle="max-height:400px"
                >
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>
                        <span class="fg-lightBlue">{{ slotProps.option.key }}</span><span class="fg-lightCobalt"> -> </span>{{ slotProps.option.label }} <span class="text-small">{{ slotProps.option.unit }}</span>
                        </div>
                    </div>
                </template>
            </Listbox>
            </div>
        </div>

        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" :disabled="!this.selectedScheme || !this.selectedParameter" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
// import helpers from "@/helpers";
import store from '@/store';
// import SocketioService from "@/services/socketioService.js";
import { mapGetters } from 'vuex';
import { isUUIDv4 } from "@/helpers";

export default defineComponent({
    name: 'dialogParameterMulti',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            editMode: true,
            entryDialog: false,
            ioDialogData: {},
            config_io: null,
            selectedScheme: null,
            selectedParameter: null,
            submitted: false,
        };
    },
    computed: {
        ...mapGetters({
            getUserGroup: 'users/getUserGroups',
            getCategory: 'types/getCategory',
            getDepartments: 'types/getDepartments',
            getUnits: 'types/getUnits',
            getIcons: 'types/getIcons',
            getModules: 'opcua/getModules',
            getModuleScheme: 'opcua/getModuleScheme',
            getModuleTypes: 'opcua/getModuleTypes',
            getModuleBindings: 'opcua/getModuleBindings',
            getObjectFactor: 'getObjectFactor',
            getObjectDecimal: 'getObjectDecimal',
        }),
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        currentModuleScheme: function () {
            if (this.selectedScheme !== null && this.getModuleScheme && this.getModuleScheme.length > 0) {
                const scheme = this.getModuleScheme.find(item => item.scheme === this.selectedScheme);
                if (Array.isArray(scheme.children)) {
                    return scheme.children;
                }
            }
            return null;
        },
    },
    created() {
        this.$store.dispatch('users/loadUserGroups');
        this.$store.dispatch('types/loadUnitTypes');
        this.$store.dispatch('types/loadCategoryTypes');
        this.$store.dispatch('types/loadDepartmentTypes');
        this.$store.dispatch('types/loadIconTypes');
        this.$store.dispatch('opcua/loadModules');
        this.$store.dispatch('opcua/loadModuleScheme');
        this.$store.dispatch('opcua/loadModuleTypes');
        this.$store.dispatch('opcua/loadModuleBindings');
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        if (this.ioDialogData.data && this.ioDialogData.data.moduleScheme) this.selectedScheme = this.ioDialogData.data.moduleScheme;
        console.log(this.ioDialogData);
    },
    methods: {
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: [],
            };
            this.selectedParameter = null;
            this.selectedScheme = null;
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i >= 0 || isUUIDv4(this.ioDialogData.i)) {
                if (this.selectedScheme !== null && this.selectedParameter !== null) {
                    this.ioDialogData.data = {
                        moduleScheme: this.selectedScheme,
                        displayName: this.selectedParameter.key,
                        key2: this.selectedParameter.key2
                    }
                }
                console.log(this.ioDialogData);
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
    },
});
</script>
